



























































































































































































































.sharing_wrapper {
  width: fit-content;
  cursor: pointer;
  color: #fff;
  display: flex;
  margin: 20px auto 0;
  background: #409eff;
  padding: 8px 10px;
  border-radius: 8px;
  gap: 5px;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
  .print_now {
    font-size: 22px;
  }
}
.while_printing_the_report {
  background: #fff;
  padding: 20px;
  direction: ltr;
  &.reverse_dir {
    direction: rtl;
  }
}
.is_old_wrapper {
  display: flex;
}
.second_elements {
  width: 200px;
  background: #fff;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .all_marks {
    font-weight: bold;
    .every_mark {
      margin-bottom: 20px;
      .box_title {
        text-align: center;
        margin-bottom: 7px;
      }
      .box_border {
        width: 70px;
        height: 70px;
        border: 2px solid #000;
        margin: auto;
        text-align: center;
        // color: #409eff;
      }
    }
  }
}
.hello_test {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  // padding: 30px 0;
  // margin: auto;
  background: #fff;
  margin-bottom: 30px;
  flex-direction: row-reverse;
  justify-content: center;
}
.wrapper_of_images {
  display: flex;
  // align-items: center;
  gap: 15px;
  margin-bottom: 10px;
  .wrapper_two {
    width: 200px;
    background: #fff;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    .loop_here {
      width: 40px;
      height: 40px;
      .item {
        width: 40px;
        height: 40px;
      }
    }
  }
}
.is_items_with_checkbox {
  .is_is_checker {
    display: flex;
    justify-content: end;
    gap: 8px;
    // margin-bottom: 5px;
  }
}
.text_images {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 6px;
}
.information {
  display: flex;
  flex-direction: row-reverse;
  background: #fff;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 30px;
  .when_name {
    margin: 0 5px;
    color: #555;
  }
  .part_one {
    // background: #ccc;
    flex-grow: 1;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    .parts {
      display: flex;
      flex-direction: row-reverse;
      width: 50%;
      &.spans {
        // display: flex;
        // gap: 20px;
        .is_span {
          display: flex;
          gap: 5px;
          .border_span {
            width: 25px;
            height: 25px;
            border: 1px solid #000;
            &.pre {
              background: #87cb16;
              border-color: #87cb16;
            }
            &.post {
              background: #87cb16;
              border-color: #87cb16;
            }
          }
        }
      }
    }
  }
  .part_two {
    .every {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      margin-bottom: 8px;
      gap: 3px;
      .is_name {
        min-width: 100px;
      }
      .item_border {
        border: 1px solid #000;
        width: 40px;
        height: 37px;
        text-align: center;
        &.date {
          width: 120px;
        }
      }
    }
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }

  .while_printing_the_report {
    height: 100%;
    width: 100%;
    page-break-after: always !important;
    // page-break-after: avoid;
    // page-break-inside: avoid;
    // page-break-after: avoid;
    // page-break-before: avoid;
    // background: red;
  }
  .for_printing_buttons {
    display: none;
  }
  .wrapper_for_printing:last-child {
    // background: green;
    page-break-after: avoid;
    page-break-inside: avoid;
    // page-break-after: avoid;
    // page-break-before: avoid;
    margin-bottom: 0px;
  }
  .while_printing_the_report:last-child {
    // background: green;
    page-break-after: avoid;
    page-break-inside: avoid;
    // page-break-after: avoid;
    // page-break-before: avoid;
    margin-bottom: 0px;
  }
}
::v-deep .el-checkbox {
  margin-bottom: 0;
}
